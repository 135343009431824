import React from 'react'
import { graphql } from 'gatsby'

export const query = graphql`
    query($slug: String!) {
        dataJson(slug: { eq: $slug }) {
            title
            state
            entries {
                question
                answer
            }
        }
    }
`

export default function AnswersPage({ data }) {
    const gqlData = data.dataJson

    return (
        <div className="container is-fluid pt-4">
            <h1 className="title is-2 has-text-centered">{gqlData.title}</h1>
            {gqlData.state === 'not-ready' ? (
                <div className="notification is-info">
                    This entry has been marked as not ready.
                </div>
            ) : null}
            <table className="table is-bordered is-striped is-hoverable is-fullwidth mb-4" style={{
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <thead>
                    <th>Question</th>
                    <th>Answer</th>
                </thead>
                <tbody>
                {
                    gqlData.entries.map(el => (
                        <tr>
                            <td>{el.question}</td>
                            <td>{el.answer}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <p className="pb-1">Debug Info:</p>
            <div className="tags has-addons">
                <span className="tag is-info">Entry Count</span>
                <span className="tag">{gqlData.entries.length}</span>
            </div>
            {/*<VocabularyList list={vocabData.vocabularyEntries} />*/}
        </div>
    )
}